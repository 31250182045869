// app/javascript/controllers/calendar_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["button"]
    static values = {
        link: String,
        config: Object
    }

    connect() {
        if (!document.querySelector('script[src*="cal.com/embed/embed.js"]')) {
            this.initializeCal()
        }
    }

    initializeCal() {
        (function(C, A, L) {
            let p = function(a, ar) { a.q.push(ar); };
            let d = C.document;
            C.Cal = C.Cal || function() {
                let cal = C.Cal;
                let ar = arguments;
                if (!cal.loaded) {
                    cal.ns = {};
                    cal.q = cal.q || [];
                    d.head.appendChild(d.createElement("script")).src = A;
                    cal.loaded = true;
                }
                if (ar[0] === L) {
                    const api = function() { p(api, arguments); };
                    const namespace = ar[1];
                    api.q = api.q || [];
                    if (typeof namespace === "string") {
                        cal.ns[namespace] = cal.ns[namespace] || api;
                        p(cal.ns[namespace], ar);
                        p(cal, ["initNamespace", namespace]);
                    } else {
                        p(cal, ar);
                    }
                    return;
                }
                p(cal, ar);
            };
        })(window, "https://app.cal.com/embed/embed.js", "init");

        window.Cal("init", "enterprise", {origin: "https://cal.com"});

        // Configure UI for element-click behavior
        window.Cal.ns.enterprise("ui", {
            hideEventTypeDetails: false,
            layout: "month_view"
        });
    }

    connect() {
        // Add necessary attributes to button when controller connects
        if (this.hasButtonTarget) {
            this.buttonTarget.setAttribute("data-cal-link", this.linkValue || "team/bynn/enterprise")
            this.buttonTarget.setAttribute("data-cal-namespace", "enterprise")
            this.buttonTarget.setAttribute("data-cal-config", JSON.stringify(this.configValue || {layout: "month_view"}))
        }

        this.initializeCal()
    }

    // No need for openCalendar method anymore as Cal.com will handle the click automatically
}
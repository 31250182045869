import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["button", "content"]

    toggle(event) {
        const button = event.currentTarget
        const sectionId = button.dataset.sectionId
        const content = this.contentTargets.find(
            el => el.dataset.sectionId === sectionId
        )

        button.classList.toggle('expanded')
        $(content).collapse('toggle')
    }
}
import { Controller } from "@hotwired/stimulus";
import Modal from "bootstrap/js/dist/modal";

export default class extends Controller {
    connect() {
        // Make the image appear clickable.
        this.element.style.cursor = "pointer";
        // Initialize transformation and filter state.
        this.rotation = 0;
        this.scale = 1;
        this.inverted = false;
        this.grayscale = false;
    }

    show(event) {
        event.preventDefault();
        const imageSrc = this.element.getAttribute("src");
        this.createModal(imageSrc);
    }

    createModal(imageSrc) {
        // Reset transformation state.
        this.rotation = 0;
        this.scale = 1;
        this.inverted = false;
        this.grayscale = false;

        // Create a container for the modal markup.
        // The control bar now includes buttons for:
        // Rotate, Zoom In, Zoom Out, Invert Colors, Toggle Grayscale, and Reset.
        this.modalContainer = document.createElement("div");
        this.modalContainer.innerHTML = `
      <div class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
          <div class="modal-content bg-transparent border-0">
            <div class="modal-body p-0 position-relative">
              <img src="${imageSrc}" class="img-fluid w-100" id="image-viewer" 
                style="transition: transform 0.3s ease, filter 0.3s ease; max-height: 90vh; margin: 0 auto; display: block;" />
              <button type="button" class="btn-close position-absolute top-0 end-0 m-3" 
                data-bs-dismiss="modal" aria-label="Close"></button>
              <div class="position-absolute bottom-0 start-50 translate-middle-x mb-3 d-flex gap-2">
                <button type="button" class="btn btn-secondary btn-sm rounded-circle" title="Rotate">
                  <i class="fa-solid fa-rotate-right"></i>
                </button>
                <button type="button" class="btn btn-secondary btn-sm rounded-circle" title="Zoom In">
                  <i class="fa-solid fa-magnifying-glass-plus"></i>
                </button>
                <button type="button" class="btn btn-secondary btn-sm rounded-circle" title="Zoom Out">
                  <i class="fa-solid fa-magnifying-glass-minus"></i>
                </button>
                <button type="button" class="btn btn-secondary btn-sm rounded-circle" title="Invert Colors">
                  <i class="fa-solid fa-adjust"></i>
                </button>
                <button type="button" class="btn btn-secondary btn-sm rounded-circle" title="Toggle Grayscale">
                  <i class="fa-solid fa-image"></i>
                </button>
                <button type="button" class="btn btn-secondary btn-sm rounded-circle" title="Reset">
                  <i class="fa-solid fa-arrows-rotate"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;

        // Append the modal container to the document.
        document.body.appendChild(this.modalContainer);

        // Grab references to the modal element and the image.
        this.modalElement = this.modalContainer.querySelector(".modal");
        this.modalImage = this.modalContainer.querySelector("#image-viewer");

        // Manually attach event listeners to the six control buttons.
        const buttons = this.modalContainer.querySelectorAll(".modal-body .btn");
        // Order is important: the order here matches the order in the HTML.
        const [rotateBtn, zoomInBtn, zoomOutBtn, invertBtn, grayscaleBtn, resetBtn] = buttons;
        rotateBtn.addEventListener("click", () => this.rotate());
        zoomInBtn.addEventListener("click", () => this.zoomIn());
        zoomOutBtn.addEventListener("click", () => this.zoomOut());
        invertBtn.addEventListener("click", () => this.invert());
        grayscaleBtn.addEventListener("click", () => this.toggleGrayscale());
        resetBtn.addEventListener("click", () => this.reset());

        // Attach a wheel event listener to the modal element for zooming.
        this.modalElement.addEventListener("wheel", (e) => this.handleWheel(e));

        // Initialize and show the Bootstrap modal.
        this.bsModal = new Modal(this.modalElement);
        this.bsModal.show();

        // When the modal is closed, remove it from the DOM.
        this.modalElement.addEventListener("hidden.bs.modal", () => {
            this.modalContainer.remove();
        });
    }

    handleWheel(event) {
        // Prevent the default scrolling behavior.
        event.preventDefault();
        // If scrolling up (deltaY negative), zoom in; if down, zoom out.
        if (event.deltaY < 0) {
            this.zoomIn();
        } else if (event.deltaY > 0) {
            this.zoomOut();
        }
    }

    rotate() {
        // Increment rotation by 90°.
        this.rotation = (this.rotation + 90) % 360;
        this.updateTransform();
    }

    zoomIn() {
        // Increase the image scale (zoom in).
        this.scale = this.scale + 0.25;
        this.updateTransform();
    }

    zoomOut() {
        // Decrease the image scale (zoom out), ensuring it does not become too small.
        if (this.scale > 0.5) {
            this.scale = this.scale - 0.25;
            this.updateTransform();
        }
    }

    invert() {
        // Toggle the inversion filter.
        this.inverted = !this.inverted;
        this.updateFilter();
    }

    toggleGrayscale() {
        // Toggle the grayscale filter.
        this.grayscale = !this.grayscale;
        this.updateFilter();
    }

    reset() {
        // Reset all transformations and filters.
        this.rotation = 0;
        this.scale = 1;
        this.inverted = false;
        this.grayscale = false;
        this.updateTransform();
        this.updateFilter();
    }

    updateTransform() {
        // Apply both rotation and scaling.
        this.modalImage.style.transform = `rotate(${this.rotation}deg) scale(${this.scale})`;
    }

    updateFilter() {
        // Build a composite filter string based on current state.
        const filters = [];
        if (this.inverted) filters.push("invert(100%)");
        if (this.grayscale) filters.push("grayscale(100%)");
        this.modalImage.style.filter = filters.length ? filters.join(" ") : "none";
    }
}
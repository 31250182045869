import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner"]
  static values = {
    url: String,
    loading: Boolean
  }

  connect() {
    this.addSpinnerOverlay()

    if (!this.loadingValue) {
      this.loadAvatar()
    }
  }

  addSpinnerOverlay() {
    const spinnerHTML = `
      <div data-dashboard--avatar-aml-target="spinner" 
           style="z-index: 10;"
           class="position-absolute bynn-aml-avatar-spinner w-100 h-100 top-0 start-0 d-none">
        <div class="bg-white opacity-75 position-absolute w-100 h-100"></div>
        <div class="d-flex align-items-center justify-content-center h-100">
          <i class="fas fa-circle-notch fa-spin text-primary fs-4"></i>
        </div>
      </div>
    `

    // Make parent relative if not already
    this.element.querySelector('.avatar-xl').style.position = 'relative'

    // Insert spinner into DOM
    this.element.querySelector('.avatar-xl').insertAdjacentHTML('beforeend', spinnerHTML)
  }

  async loadAvatar() {
    if (this.loadingValue) return

    try {
      this.loadingValue = true
      const url = this.element.dataset.url

      if (!url) {
        console.warn("No URL provided for avatar loading")
        return
      }

      this.showSpinner()

      const response = await fetch(url, {
        headers: {
          "Accept": "text/vnd.turbo-stream.html"
        }
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const streamText = await response.text()
      Turbo.renderStreamMessage(streamText)

    } catch (error) {
      console.error("Error loading avatar:", error)
    } finally {
      this.loadingValue = false
      setTimeout(() => this.hideSpinner(), 300)
    }
  }

  showSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove('d-none')
    }
  }

  hideSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add('d-none')
    }
  }
}